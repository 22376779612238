/*
Theme Name: Glenn
Author: Longevity Graphics
Author URI: http://www.longevitygraphics.com
Version: 1.0
Template: twentyten


div{
    border: 1px solid red !important;
}*/


/*@import url("../twentyten/style.css");*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

body {
    line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: '';
    content: none;
}

del {
    text-decoration: line-through;
}


/* tables still need 'cellspacing="0"' in the markup */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a img {
    border: none;
}


/* =Layout
-------------------------------------------------------------- */


/*
LAYOUT: Two columns
DESCRIPTION: Two-column fixed layout with one sidebar right of content
*/

.home #container {
    float: left;
    margin: 0;
    width: 100%;
    padding: 0;
}

#container {
    box-sizing: border-box;
    display: table-cell;
    margin: 0;
    padding: 0 40px;
    width: 100%;
}

#content {
    margin: 0;
}

#primary,
#secondary {
    display: table-cell;
    min-width: 320px;
    width: 320px;
}

#secondary {
    clear: right;
}

#footer {
    clear: both;
    width: 100%;
}


/*
LAYOUT: Full width, no sidebar
DESCRIPTION: Full width content with no sidebar; used for attachment pages
*/

.single-attachment #content {
    margin: 0 auto;
    width: 900px;
}


/* =Fonts
-------------------------------------------------------------- */

body,
input,
textarea,
.page-title span,
.pingback a.url {
    font-family: Lato, Georgia, "Bitstream Charter", serif;
}

h3#comments-title,
h3#reply-title,
#access .menu,
#access div.menu ul,
#cancel-comment-reply-link,
.form-allowed-tags,
#site-info,
#site-title,
#wp-calendar,
.comment-meta,
.comment-body tr th,
.comment-body thead th,
.entry-content label,
.entry-content tr th,
.entry-content thead th,
.entry-meta,
.entry-title,
.entry-utility,
#respond label,
.navigation,
.page-title,
.pingback p,
.reply,
.widget-title,
.wp-caption-text {
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif;
}

input[type="submit"] {
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif;
}

pre {
    font-family: "Courier 10 Pitch", Courier, monospace;
}

code {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}


/* =Structure
-------------------------------------------------------------- */


/* The main theme structure */

div.menu,
#branding,
#wrapper {
    margin: 0 auto;
    width: 1240px;
}

#wrapper {
    background: #fff;
    margin-top: 20px;
    box-sizing: border-box;
    clear: both;
}

.home #wrapper {
    background: #f1f1f1;
}


/* Structure the footer area */

#footer-widget-area {
    overflow: hidden;
}

#footer-widget-area .widget-area {
    float: left;
    margin-right: 20px;
    width: 220px;
}

#footer-widget-area #fourth {
    margin-right: 0;
}

#site-info {
    float: left;
    font-size: 14px;
    font-weight: bold;
    width: 700px;
}

#site-generator {
    float: right;
    width: 220px;
}


/* =Global Elements
-------------------------------------------------------------- */


/* Main global 'theme' and typographic styles */

.page-id-2834 img {
    max-width: 100%;
    height: auto;
}

body.home {
    background: #f1f1f1;
}

body {
    background: #fff;
}

body,
input,
textarea {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

hr {
    background-color: #e7e7e7;
    border: 0;
    clear: both;
    height: 1px;
    margin-bottom: 18px;
}


/* Text elements */

p {
    margin-bottom: 18px;
}

ul {
    list-style: disc;
    margin: 0 0 18px 1.5em;
}

ol {
    list-style: decimal;
    margin: 0 0 18px 1.5em;
}

ol ol {
    list-style: upper-alpha;
}

ol ol ol {
    list-style: lower-roman;
}

ol ol ol ol {
    list-style: lower-alpha;
}

ul ul,
ol ol,
ul ol,
ol ul {
    margin-bottom: 0;
}

dl {
    margin: 0 0 24px 0;
}

dt {
    font-weight: bold;
    margin-bottom: 10px;
}

dd {
    margin-bottom: 18px !important;
}

strong {
    font-weight: 500;
}

cite,
em,
i {
    font-style: italic;
}

big {
    font-size: 131.25%;
}

ins {
    background: #ffc;
    text-decoration: none;
}

blockquote {
    font-style: italic;
    padding: 0 3em;
}

blockquote cite,
blockquote em,
blockquote i {
    font-style: normal;
}

pre {
    background: #f7f7f7;
    color: #222;
    line-height: 18px;
    margin-bottom: 18px;
    overflow: auto;
    padding: 1.5em;
}

abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}

b {
    font-weight: 500;
}

sup,
sub {
    height: 0;
    line-height: 1;
    position: relative;
    vertical-align: baseline;
}

sup {
    bottom: 1ex;
}

sub {
    top: .5ex;
}

small {
    font-size: smaller;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="tel"],
textarea {
    background: #fff;
    box-sizing: border-box;
    padding: 3px 8px;
    width: 100%;
    border: none;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #cfcfcf;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

a:link,
a:visited,
a:not(:hover),
a i {
    color: #c09232;
    text-decoration: none;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

a:active,
a:hover,
a i:hover {
    color: #55421c;
    cursor: pointer;
    text-decoration: none;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}


/* Text meant only for screen readers */

.screen-reader-text {
    position: absolute;
    left: -9000px;
}


/* =Menu
-------------------------------------------------------------- */

#access {
    float: right;
    margin-top: 36px;
    margin-right: -5px;
}

#access .menu-header ul,
div.menu ul {
    list-style: none;
    margin: 0;
}

#access .menu-header li,
div.menu li {
    float: left;
    position: relative;
}

#access .menu-header li:last-child a,
div.menu li:last-child a {
    background: #c09231;
    margin: 0 5px;
    padding: 6px 10px 6px;
    border-bottom: none;
    text-shadow: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#access a {
    border-bottom: 5px solid transparent;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
    padding: 6px 5px 6px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px #000;
}

.chrome .home #access a {
    font-weight: 300;
}

#headwrap #access a {
    color: #333;
    text-shadow: none;
}

#headwrap #access ul ul a,
#headwrap #access li:last-child a {
    color: #fff;
    text-shadow: none;
}

#access ul ul {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    position: absolute;
    top: 36px;
    left: 0;
    float: left;
    width: 235px;
    z-index: 99999;
    margin: 0 10px !important;
    opacity: 0;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

#access ul ul li {
    min-width: 235px;
}

#access ul ul ul {
    left: 100%;
    top: 0;
}

#access ul ul a,
#access ul ul li:last-child a {
    background: #261f13 none repeat scroll 0 0 !important;
    border-bottom: 1px solid #372c19 !important;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    height: auto;
    line-height: normal;
    margin: 0 !important;
    padding: 8px 10px !important;
    text-transform: capitalize;
    width: 235px;
    text-shadow: none;

    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
}

#access ul ul :hover>a,
#access ul ul li:last-child a:hover,
div#header div#access ul.sub-menu li.current_page_item a {
    background: #c09231 none repeat scroll 0 0 !important;
}

#access ul li:hover>ul {
    visibility: visible;
    opacity: 1;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

#access ul li.current_page_item>a,
#access ul li.current_page_ancestor>a,
#access ul li.current-menu-ancestor>a,
#access ul li.current-menu-item>a,
#access ul li.current-menu-parent>a {
    color: #fff;
    border-bottom: 5px solid #c09231;
    padding: 6px 5px 1px;
}

* html #access ul li.current_page_item a,
* html #access ul li.current_page_ancestor a,
* html #access ul li.current-menu-ancestor a,
* html #access ul li.current-menu-item a,
* html #access ul li.current-menu-parent a,
* html #access ul li a:hover {
    color: #fff;
    border-bottom: 5px solid #c09231;
    padding: 6px 5px 1px;
}


/* =Content
-------------------------------------------------------------- */

.home #main {
    padding: 40px 0 0;
}

#main {
    clear: both;
    overflow: hidden;
    padding: 0;
}

#content {
    margin-bottom: 36px;
}

#content,
#content input,
#content textarea {
    color: #4f4f4f;
    font-size: 16px;
    line-height: 24px;
}

#content p,
#content ul,
#content ol,
#content dd,
#content pre,
#content hr {
    margin-bottom: 24px;
}

#content ul ul,
#content ol ol,
#content ul ol,
#content ol ul {
    margin-bottom: 0;
}

#content pre,
#content kbd,
#content tt,
#content var {
    font-size: 15px;
    line-height: 21px;
}

#content code {
    font-size: 13px;
}

#content dt,
#content th {
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto Slab;
    font-weight: 300;
}

#content h1,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6 {
    color: #000;
    line-height: 1.5em;
    margin: 0 0 20px 0;
}

.home #content h2 {
    color: #c09232;
    font-size: 36px;
    font-weight: 200;
    line-height: normal;
}

.safari .home #content h2 {
    font-weight: 300;
}

#content h2 {
    color: #c09232;
    font-size: 24px;
    font-weight: 200;
    line-height: 30px;
}

.safari #content h2 {
    font-weight: 300;
}

#content h2 strong {
    font-weight: 200;
}

.safari #content h2 strong {
    font-weight: 300;
}

#content h3 {
    font-size: 24px;
    font-weight: 200;
    line-height: 30px;
}

.safari #content h3 {
    font-weight: 300;
}

#content table {
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
}

#content tr th,
#content thead th {
    color: #777;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    padding: 9px 24px;
}

#content tr td {
    /*padding: 6px 24px;*/
}

#content tr.odd td {
    background: #f2f7fc;
}

.hentry {
    margin: 0 0 48px 0;
}

.home .sticky {
    background: #f2f7fc;
    border-top: 4px solid #000;
    margin-left: -20px;
    margin-right: -20px;
    padding: 18px 20px;
}

.single .hentry {
    margin: 0 0 36px 0;
}

.page-title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 36px 0;
}

.page-title span {
    color: #333;
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
}

.page-title a:link,
.page-title a:visited {
    color: #777;
    text-decoration: none;
}

.page-title a:active,
.page-title a:hover {
    color: #ff4b33;
}

.home #content .entry-title {
    display: none;
}

#content .entry-title {
    color: #000;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom: 0;
}

.entry-title a:link,
.entry-title a:visited {
    color: #000;
    text-decoration: none;
}

.entry-title a:active,
.entry-title a:hover {
    color: #ff4b33;
}

.entry-meta {
    color: #777;
    font-size: 12px;
}

.entry-meta abbr,
.entry-utility abbr {
    border: none;
}

.entry-meta abbr:hover,
.entry-utility abbr:hover {
    border-bottom: 1px dotted #666;
}

.entry-content,
.entry-summary {
    clear: both;
    padding: 0;
}

#content .entry-summary p:last-child {
    margin-bottom: 12px;
}

.entry-content fieldset {
    border: 1px solid #e7e7e7;
    margin: 0 0 24px 0;
    padding: 24px;
}

.entry-content fieldset legend {
    background: #fff;
    color: #000;
    font-weight: bold;
    padding: 0 24px;
}

.entry-content input {
    margin: 0;
}

.entry-content input.file,
.entry-content input.button {
    margin-right: 24px;
}

.entry-content label {
    color: #777;
    font-size: 12px;
}

.checkbox-962 {
    .wpcf7-list-item {
        // outline: solid 1px red;
        display: block;
        margin-top: 6px;
    }

    .wpcf7-list-item-label {
        font-size: 16px;
    }
}

.entry-content select {
    margin: 0 0 24px 0;
}

.entry-content sup,
.entry-content sub {
    font-size: 10px;
}

.entry-content blockquote.left {
    float: left;
    margin-left: 0;
    margin-right: 24px;
    text-align: right;
    width: 33%;
}

.entry-content blockquote.right {
    float: right;
    margin-left: 24px;
    margin-right: 0;
    text-align: left;
    width: 33%;
}

.page-link {
    clear: both;
    color: #000;
    font-weight: bold;
    line-height: 48px;
    word-spacing: 0.5em;
}

.page-link a:link,
.page-link a:visited {
    background: #f1f1f1;
    color: #333;
    font-weight: normal;
    padding: 0.5em 0.75em;
    text-decoration: none;
}

.home .sticky .page-link a {
    background: #d9e8f7;
}

.page-link a:active,
.page-link a:hover {
    color: #ff4b33;
}

body.page .edit-link {
    clear: both;
    display: block;
}

#entry-author-info {
    background: #ecdec1 none repeat scroll 0 0;
    border-top: 4px solid #c09231;
    clear: both;
    font-size: 14px;
    line-height: 20px;
    margin: 24px 0;
    overflow: hidden;
    padding: 18px 20px;
}

#entry-author-info #author-avatar {
    background: #fff none repeat scroll 0 0;
    float: left;
    height: 60px;
    margin: 0 -104px 0 0;
    padding: 8px;
}

#entry-author-info #author-description {
    color: #4f4f4f;
    float: left;
    margin: 0 0 0 104px;
}

#entry-author-info h2 {
    color: #000;
    font-family: roboto slab;
    font-size: 100%;
    font-weight: 300;
    margin-bottom: 0;
}

.entry-utility {
    clear: both;
    color: #777;
    font-size: 12px;
    line-height: 18px;
}

.entry-meta a,
.entry-utility a {
    color: #777;
}

.entry-meta a:hover,
.entry-utility a:hover {
    color: #ff4b33;
}

#content .video-player {
    padding: 0;
}


/* =Asides
-------------------------------------------------------------- */

.home #content .format-aside p,
.home #content .category-asides p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 0;
}

.home .hentry.format-aside,
.home .hentry.category-asides {
    padding: 0;
}

.home #content .format-aside .entry-content,
.home #content .category-asides .entry-content {
    padding-top: 0;
}


/* =Gallery listing
-------------------------------------------------------------- */

.format-gallery .size-thumbnail img,
.category-gallery .size-thumbnail img {
    border: 10px solid #f1f1f1;
    margin-bottom: 0;
}

.format-gallery .gallery-thumb,
.category-gallery .gallery-thumb {
    float: left;
    margin-right: 20px;
    margin-top: -4px;
}

.home #content .format-gallery .entry-utility,
.home #content .category-gallery .entry-utility {
    padding-top: 4px;
}


/* =Attachment pages
-------------------------------------------------------------- */

.attachment .entry-content .entry-caption {
    font-size: 140%;
    margin-top: 24px;
}

.attachment .entry-content .nav-previous a:before {
    content: '\2190\00a0';
}

.attachment .entry-content .nav-next a:after {
    content: '\00a0\2192';
}


/* =Images
-------------------------------------------------------------- */


/*
Resize images to fit the main content area.
- Applies only to images uploaded via WordPress by targeting size-* classes.
- Other images will be left alone. Use "size-auto" class to apply to other images.
*/

img.size-auto,
img.size-full,
img.size-large,
img.size-medium,
.attachment img,
.widget-container img {
    max-width: 100%;
    /* When images are too wide for containing element, force them to fit. */
    height: auto;
    /* Override height to match resized width for correct aspect ratio. */
}

.alignleft,
img.alignleft {
    display: inline;
    float: left;
    margin-right: 24px;
    margin-top: 4px;
}

.alignright,
img.alignright {
    display: inline;
    float: right;
    margin-left: 24px;
    margin-top: 4px;
}

.aligncenter,
img.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 12px;
}

.wp-caption {
    background: #f1f1f1;
    line-height: 18px;
    margin-bottom: 20px;
    max-width: 632px !important;
    /* prevent too-wide images from breaking layout */
    padding: 4px;
    text-align: center;
}

.wp-caption img {
    margin: 5px 5px 0;
    max-width: 622px;
    /* caption width - 10px */
}

.wp-caption p.wp-caption-text {
    color: #777;
    font-size: 12px;
    margin: 5px;
}

.wp-smiley {
    margin: 0;
}

.gallery {
    margin: 0 auto 18px;
}

.gallery .gallery-item {
    float: left;
    margin-top: 0;
    text-align: center;
    width: 33%;
}

.gallery-columns-2 .gallery-item {
    width: 50%;
}

.gallery-columns-4 .gallery-item {
    width: 25%;
}

.gallery img {
    border: medium none;
    box-shadow: 0 1px 3px #ccc;
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    padding: 5px;
}

.gallery-columns-2 .attachment-medium {
    max-width: 92%;
    height: auto;
}

.gallery-columns-4 .attachment-thumbnail {
    max-width: 84%;
    height: auto;
}

.gallery .gallery-caption {
    color: #4f4f4f;
    font-size: 12px;
    line-height: normal;
}

.gallery dl {
    margin: 0;
}

.gallery br+br {
    display: none;
}

#content .attachment img {
    /* single attachment images should be centered */
    display: block;
    margin: 0 auto;
}


/* =Navigation
-------------------------------------------------------------- */

.navigation {
    color: #4f4f4f;
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
}

.navigation a:link,
.navigation a:visited {
    color: #777;
    text-decoration: none;
}

.navigation a:active,
.navigation a:hover {
    color: #c09231;
}

.nav-previous {
    float: left;
    width: 50%;
}

.nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

#nav-above {
    margin: 0 0 18px 0;
}

#nav-above {
    display: none;
}

.paged #nav-above,
.single #nav-above {
    display: block;
}

#nav-below {
    margin: 30px 0 0;
}


/* =Comments
-------------------------------------------------------------- */

#comments {
    clear: both;
}

#comments .navigation {
    padding: 0 0 18px 0;
}

h3#comments-title,
h3#reply-title {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
}

h3#comments-title {
    padding: 24px 0;
}

.commentlist {
    list-style: none;
    margin: 0;
}

.commentlist li.comment {
    border-bottom: 1px solid #e7e7e7;
    line-height: 24px;
    margin: 0 0 24px 0;
    padding: 0 0 0 56px;
    position: relative;
}

.commentlist li:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

#comments .comment-body ul,
#comments .comment-body ol {
    margin-bottom: 18px;
}

#comments .comment-body p:last-child {
    margin-bottom: 6px;
}

#comments .comment-body blockquote p:last-child {
    margin-bottom: 24px;
}

.commentlist ol {
    list-style: decimal;
}

.commentlist .avatar {
    position: absolute;
    top: 4px;
    left: 0;
}

.comment-author {}

.comment-author cite {
    color: #000;
    font-style: normal;
    font-weight: bold;
}

.comment-author .says {
    font-style: italic;
}

.comment-meta {
    font-size: 12px;
    margin: 0 0 18px 0;
}

.comment-meta a:link,
.comment-meta a:visited {
    color: #777;
    text-decoration: none;
}

.comment-meta a:active,
.comment-meta a:hover {
    color: #ff4b33;
}

.commentlist .even {}

.commentlist .bypostauthor {}

.reply {
    font-size: 12px;
    padding: 0 0 24px 0;
}

.reply a,
a.comment-edit-link {
    color: #777;
}

.reply a:hover,
a.comment-edit-link:hover {
    color: #ff4b33;
}

.commentlist .children {
    list-style: none;
    margin: 0;
}

.commentlist .children li {
    border: none;
    margin: 0;
}

.nopassword,
.nocomments {
    display: none;
}

#comments .pingback {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 18px;
    padding-bottom: 18px;
}

.commentlist li.comment+li.pingback {
    margin-top: -6px;
}

#comments .pingback p {
    color: #777;
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
}

#comments .pingback .url {
    font-size: 13px;
    font-style: italic;
}

input[type="submit"],
#estimate .btn {
    background: #ebbf38;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ebbf38 0%, #c39532 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ebbf38), color-stop(100%, #c39532));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ebbf38 0%, #c39532 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ebbf38 0%, #c39532 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ebbf38 0%, #c39532 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ebbf38 0%, #c39532 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebbf38', endColorstr='#c39532', GradientType=0);
    /* IE6-9 */
    border: medium none;
    border-radius: 3px;
    color: #fff !important;
    margin-top: 6px;
    padding: 2px 0;
    text-transform: uppercase;
    width: 100%;
    font-family: Roboto Slab;
}

input[type="submit"]:hover,
#estimate .btn:hover {
    background: #c39532;
    /* Old browsers */
    background: -moz-linear-gradient(top, #c39532 0%, #ebbf38 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #c39532), color-stop(100%, #ebbf38));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #c39532 0%, #ebbf38 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c39532 0%, #ebbf38 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c39532 0%, #ebbf38 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #c39532 0%, #ebbf38 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c39532', endColorstr='#ebbf38', GradientType=0);
    /* IE6-9 */
    cursor: pointer;
}

#respond {
    border-top: 1px solid #e7e7e7;
    margin: 24px 0;
    overflow: hidden;
    position: relative;
}

#respond p {
    margin: 0;
}

#respond .comment-notes {
    margin-bottom: 1em;
}

.form-allowed-tags {
    line-height: 1em;
}

.children #respond {
    margin: 0 48px 0 0;
}

h3#reply-title {
    margin: 18px 0;
}

#comments-list #respond {
    margin: 0 0 18px 0;
}

#comments-list ul #respond {
    margin: 0;
}

#cancel-comment-reply-link {
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}

#respond .required {
    color: #ff4b33;
    font-weight: bold;
}

#respond label {
    color: #777;
    font-size: 12px;
}

#respond input {
    margin: 0 0 9px;
    width: 98%;
}

#respond textarea {
    width: 98%;
}

#respond .form-allowed-tags {
    color: #777;
    font-size: 12px;
    line-height: 18px;
}

#respond .form-allowed-tags code {
    font-size: 11px;
}

#respond .form-submit {
    margin: 12px 0;
}

#respond .form-submit input {
    font-size: 14px;
    width: auto;
}


/* =Widget Areas
-------------------------------------------------------------- */

.widget-area ul {
    list-style: none;
    margin-left: 0;
}

.widget-area ul ul {
    list-style: square;
    margin-left: 1.3em;
}

.widget-area select {
    max-width: 100%;
}

.widget_search #s {
    /* This keeps the search inputs in line */
    width: 60%;
}

.widget_search label {
    display: none;
}

.widget-container {
    /*word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;*/
    margin: 0 0 18px 0;
}

.widget-container .wp-caption img {
    margin: auto;
}

.widget-title {
    color: #222;
    font-weight: bold;
}

.widget-area a:link,
.widget-area a:visited {
    text-decoration: none;
}

.widget-area a:active,
.widget-area a:hover {
    text-decoration: underline;
}

.widget-area .entry-meta {
    font-size: 11px;
}

#wp_tag_cloud div {
    line-height: 1.6em;
}

#wp-calendar {
    width: 100%;
}

#wp-calendar caption {
    color: #222;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 4px;
    text-align: left;
}

#wp-calendar thead {
    font-size: 11px;
}

#wp-calendar thead th {}

#wp-calendar tbody {
    color: #aaa;
}

#wp-calendar tbody td {
    background: #f5f5f5;
    border: 1px solid #fff;
    padding: 3px 0 2px;
    text-align: center;
}

#wp-calendar tbody .pad {
    background: none;
}

#wp-calendar tfoot #next {
    text-align: right;
}

.widget_rss a.rsswidget {
    color: #000;
}

.widget_rss a.rsswidget:hover {
    color: #ff4b33;
}

.widget_rss .widget-title img {
    width: 11px;
    height: 11px;
}


/* Main sidebars */

#main .widget-area ul {
    margin: 0;
    padding: 0;
}

#main .widget-area ul ul {
    border: none;
    margin-left: 1.3em;
    padding: 0;
}

#primary {}

#secondary {}


/* Footer widget areas */

#footer-widget-area {}


/* =Footer
-------------------------------------------------------------- */

#footer {
    margin-bottom: 20px;
}

#site-info {
    font-weight: bold;
}

#site-info a {
    color: #000;
    text-decoration: none;
}

#site-generator {
    font-style: italic;
    position: relative;
}

#site-generator a {
    background: url(images/wordpress.png) center left no-repeat;
    color: #666;
    display: inline-block;
    line-height: 16px;
    padding-left: 20px;
    text-decoration: none;
}

#site-generator a:hover {
    text-decoration: underline;
}

img#wpstats {
    display: block;
    margin: 0 auto 10px;
}


/* =Mobile Safari ( iPad, iPhone and iPod Touch )
-------------------------------------------------------------- */

pre {
    -webkit-text-size-adjust: 140%;
}

code {
    -webkit-text-size-adjust: 160%;
}

#access,
.entry-meta,
.entry-utility,
.navigation,
.widget-area {
    -webkit-text-size-adjust: 120%;
}

#site-description {
    -webkit-text-size-adjust: none;
}


/* =Print Style
-------------------------------------------------------------- */

@media print {
    body {
        background: none !important;
    }
    #wrapper {
        clear: both !important;
        display: block !important;
        float: none !important;
        position: relative !important;
    }
    #header {
        border-bottom: 2pt solid #000;
        padding-bottom: 18pt;
    }
    #site-title,
    #site-description {
        float: none;
        line-height: 1.4em;
        margin: 0;
        padding: 0;
    }
    #site-title {
        font-size: 13pt;
    }
    .entry-content {
        font-size: 14pt;
        line-height: 1.6em;
    }
    .entry-title {
        font-size: 21pt;
    }
    #access,
    #branding img,
    #respond,
    .comment-edit-link,
    .edit-link,
    .navigation,
    .page-link,
    .widget-area {
        display: none !important;
    }
    #container,
    #header,
    #footer {
        margin: 0;
        width: 100%;
    }
    #content,
    .one-column #content {
        margin: 24pt 0 0;
        width: 100%;
    }
    .wp-caption p {
        font-size: 11pt;
    }
    #site-info,
    #site-generator {
        float: none;
        width: auto;
    }
    img#wpstats {
        display: none;
    }
    #site-generator a {
        margin: 0;
        padding: 0;
    }
    #entry-author-info {
        border: 1px solid #e7e7e7;
    }
    #main {
        display: inline;
    }
    .home .sticky {
        border: none;
    }
}


/*********************** eBOOK ******************************/

#eBook .icon {
    background: transparent url("./images/fab_raving.png") no-repeat scroll left top;
}

#eBook {
    background: #f1f1f1;
    line-height: normal;
    padding: 30px 0px;
    box-sizing: border-box;
}

#eBook .container {
    max-width: 1200px;
    margin: 0 auto;
    color: #4f4f4f;
    line-height: 24px !important;
}

.btn.gold {
    background-color: #c09231;
    clear: both;
    color: #fff !important; // display: block;
    font-family: Roboto Slab !important;
    font-size: 15px !important;

    font-weight: 400 !important;
    margin: 30px 0px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase !important;
    width: 50%;
}

.btn.gold:hover {
    background-color: #444
}

.padding {
    padding: 10px !important;
    box-sizing: border-box;
}

#eBook h3 {
    color: #c09231;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 25px;
}

#eBook .right {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    padding: 0px 0px 0px 20px;
    box-sizing: border-box;
}

#eBook .left {
    display: inline-block;
    width: 30%;
}

#eBook .left img {
    max-width: 100%;
    height: auto;
}


/**********************************TOP BAR**********************************/

#topbarwrap,
#bottombarwrap {
    background: #261f13 none repeat scroll 0 0;
    /*box-shadow: 0 0 100px #000;*/
    min-height: 38px;
    position: relative;
    z-index: 999999;
}

#topbar .booksignup {
    float: left;
    max-height: 38px;
    padding: 8px 0px 0px 20px;
}

#topbar .search {
    float: left;
    text-align: right;
    max-height: 38px;
}

#searchform {
    box-sizing: border-box;
    padding-top: 5px;
}

#searchform input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #55421c;
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    padding: 4px 25px 4px 8px !important;
    line-height: 18px;

    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.chrome #searchform input {
    line-height: 19px;
}

.safari #searchform input {
    line-height: 17px;
}

#searchform input#searchsubmit {
    background-color: #55421c;
    background-image: url("./images/mag.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 14px 14px !important;
    border-color: #755a22 #755a22 #755a22 -moz-use-text-color;
    border-style: solid solid solid none;
    border-width: 1px 1px 1px medium;
    margin-left: -4px;
    padding: 3px 4px 3px 21px !important;
    text-indent: -999px;
    max-width: 31px;
    position: relative;
    top: -34px;

    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

#searchform input#searchsubmit:hover {
    cursor: pointer;
}

.social {
    float: right;
    margin: 0;
    padding-top: 8px;
}

.social li {
    display: inline-block;
}

.social li a {
    display: block;
    height: 22px;
    text-indent: -9999px;
    width: 22px;
    margin-left: 10px;
}

.social li a#facebook {
    background: url('./images/social_facebook.png') no-repeat 0 0 transparent;
}

.social li a#twitter {
    background: url('./images/social_twitter.png') no-repeat 0 0 transparent;
}

.social li a#google {
    background: url('./images/social_googleplus.png') no-repeat 0 0 transparent;
}

.social li a#linkedin {
    background: url('./images/social_linkedin.png') no-repeat 0 0 transparent;
}

.social li a#youtube {
    background: url('./images/social_youtube.png') no-repeat 0 0 transparent;
}


/**********************************SLIDER**********************************/

#plax-slide {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    background: url('./images/slide-shadow.png') repeat-x center -2px transparent;
}


/**********************************HEADER**********************************/

#headwrap {
    background: #fff none repeat scroll 0 0;
    height: 115px;
}

#headwrap #header {}

#logo {
    display: inline-block;
    float: left;
    margin-top: 20px;
    position: relative;
}

#logo h1 {
    text-indent: -9999px;
}

#slide #text-slider {
    clear: both;
    float: left;
    height: auto;
    line-height: normal;
    max-width: 560px;
    overflow: visible;
    position: relative;
    margin-top: 40px;
}

#slide #text-slider .text-slideshow {}

#slide #text-slider .text-slideshow article {
    box-sizing: border-box;
    font-family: Roboto Slab;
    font-size: 34px;
    font-weight: 300;
    padding: 25px 30px 30px 50px;

    background: rgba(0, 0, 0, 0.75) none repeat scroll 0 0;
    border-left: 5px solid #c09231;
}

#text-slider .text-slideshow article span {
    display: block;
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 18px;
    max-width: 90%;
}

#text-slider #text-slider-controls {
    float: left;
    font-size: 20px;
    margin: 20px 0 0;
}

#text-slider #text-slider-controls a i {
    background: #c09231 none repeat scroll 0 0;
    box-sizing: border-box;
    color: #fff;
    font-size: 24px;
    padding: 6px 10px 4px;
    display: none;
}

#text-slider #text-slider-controls a i:hover {
    background: #55421c none repeat scroll 0 0;
}

#estimate {
    background: rgba(0, 0, 0, 0.75) none repeat scroll 0 0;
    box-sizing: border-box;
    margin-top: 40px;
    max-width: 340px;
    padding: 25px 30px;
    text-align: center;
    float: right;
    margin-bottom: 30px;
}

#estimate h3 {
    color: #c09231;
    font-size: 34px;
    line-height: normal;
    margin-bottom: 18px;
}

#estimate span {
    display: block;
    margin-bottom: -12px;
}

#estimate input {
    color: #000;
}

#estimate input.wpcf7-not-valid {
    background: #ecb4b4 none repeat scroll 0 0;
}

div.wpcf7-validation-errors {
    border: medium none !important;
    color: #c09231;
    font-size: 13px;
    line-height: normal;
    margin: 0 !important;
    padding: 0 !important;
}

div.wpcf7-mail-sent-ok {
    display: none !important;
}

#estimate div.wpcf7-validation-errors,
#estimate span.wpcf7-not-valid-tip {
    display: none !important;
}

div.wpcf7 img.ajax-loader {
    margin-top: 12px;
    position: absolute;
}


/**********************************TITLE WRAP**********************************/

#pagetitle-wrap {
    background: #f1f1f1 none repeat scroll 0 0;
    height: 100px;
}

#pagetitle-wrap #pagetitle {
    box-sizing: border-box;
    line-height: 100px;
    padding: 0 40px;
}

#pagetitle-wrap #pagetitle h1 {
    color: #c09231;
    float: left;
    font-family: roboto slab;
    font-weight: 200;
    line-height: 100px;
    font-size: 36px;
}

.safari #pagetitle-wrap #pagetitle h1 {
    font-weight: 300;
}

#pagetitle-wrap #pagetitle #breadcrumbs {
    float: right;
    font-size: 14px;
    margin-bottom: 0;
    color: #4f4f4f;
}

#pagetitle-wrap #pagetitle #breadcrumbs a,
#pagetitle-wrap #pagetitle #breadcrumbs .breadcrumb_last {
    color: #4f4f4f;
}

#pagetitle-wrap #pagetitle #breadcrumbs .breadcrumb_last {
    text-decoration: underline;
}



/**********************************SPLASH**********************************/

#splash-wrap {
    background: #f1f1f1;
}

#splash {
    color: #4f4f4f;
    position: relative;
}

#splash li {
    list-style: outside none none;
    margin: 0;
}

#splash .widget-title {
    color: #c09231;
    font-family: Roboto Slab;
    font-size: 36px;
    font-weight: 200;
    padding: 35px 0 50px;
    text-align: center;
    margin-bottom: 0px;
}

.safari #splash .widget-title {
    font-weight: 300;
}

#splash h3 {
    color: #000;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 18px;
    clear: none;
    line-height: normal;
}

.safari #splash h3 {
    font-weight: 300;
}

#splash .textwidget {
    max-width: 750px;
    padding: 0 0 50px 50px;
}

#splash #glenn {
    bottom: 0;
    margin: 0;
    position: absolute;
    right: 50px;
}


/**********************************FEATURES**********************************/

#fab-wrap {
    background: #fff none repeat scroll 0 0;
    color: #4f4f4f;
}

#fab-title {
    background: #c09231 none repeat scroll 0 0;
    line-height: normal;
    padding: 35px 0 50px;
    text-align: center;
}

#fab-title h3 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
}

.chrome #fab-title h3 {
    font-weight: 300;
}

#fab-title p {
    box-sizing: border-box;
    color: #444;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding: 15px 100px 0;
}

#fab {
    line-height: 22px;
    padding: 50px 0 20px;
}

#fab li {
    list-style: none;
    margin: 0;
}

#fab .fab {
    display: inline-block;
    margin-bottom: 40px;
    vertical-align: top;
    width: 33%;
}

#fab .fab .content {
    box-sizing: border-box;
    padding: 0 10px 0 45px;
}

#fab .fab .title {
    font-family: roboto slab;
    font-size: 24px;
    font-weight: 200;
    line-height: normal;
    padding: 0 0 15px 45px;
}

.safari #fab .fab .title {
    font-weight: 300;
}

#fab .fab .title#edu {
    background: url('./images/fab_edu.png') no-repeat top left transparent;
}

#fab .fab .title#over12 {
    background: url('./images/fab_over12.png') no-repeat top left transparent;
}

#fab .fab .title#detailed {
    background: url('./images/fab_detailed.png') no-repeat top left transparent;
}

#fab .fab .title#strata {
    background: url('./images/fab_strata.png') no-repeat top left transparent;
}

#fab .fab .title#first {
    background: url('./images/fab_first.png') no-repeat top left transparent;
}

#fab .fab .title#app {
    background: url('./images/fab_app.png') no-repeat top left transparent;
}

#fab .fab .title#raving {
    background: url('./images/fab_raving.png') no-repeat top left transparent;
}

#fab .fab .title#attics {
    background: url('./images/fab_attics.png') no-repeat top left transparent;
}

#fab .fab .title#friendly {
    background: url('./images/fab_friendly.png') no-repeat -3px top transparent;
}


/**********************************NAVIGATION**********************************/

.home #click-menu {
    background: rgba(0, 0, 0, 0.75) !important;
}

#click-menu {
    background: none !important;
    padding: 10px !important;
}

#responsive-menu #responsive-menu-title,
#responsive-menu #responsive-menu-title a {
    display: none;
}

#responsive-menu.RMOpened {
    margin-top: 38px;
}

#responsive-menu.RMOpened .hvr-shutter-out-vertical a,
#wrapper .hvr-shutter-out-vertical {
    background: #261f13 none repeat scroll 0 0 !important;
    color: #fff !important;
    font-weight: normal !important;
}


/**********************************HOME CONTENT**********************************/

.home .entry-content .one_half {
    padding-right: 15px;
    box-sizing: border-box;
}

.home .entry-content .last_column {
    padding-left: 15px;
    padding-right: 0px;
    box-sizing: border-box;
}

.home .entry-content h2 {
    margin-bottom: 70px !important;
    text-align: center;
}


/**********************************SIDEBAR**********************************/

#primary {
    position: relative;
    top: 30px;
}

#primary .widget-container {
    border-top: 5px solid #c09231;
    box-shadow: 0 1px 3px #ccc;
    color: #4f4f4f;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 40px;
    padding: 30px;
    position: relative;
    right: 3px;
}

#primary .widget-container h3 {
    color: #c09231;
    font-family: Roboto Slab;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 24px;
    text-align: center;
}

.safari #primary .widget-container h3 {
    font-weight: 300;
}

#primary .wpcf7-form {
    margin-bottom: -10px;
}

#primary .wpcf7-form br,
#primary .wpcf7-validation-errors {
    display: none !important;
}

#primary input.wpcf7-not-valid {
    background: #ecb4b4 none repeat scroll 0 0 !important;
}

#primary input[type="text"],
#primary input[type="password"],
#primary input[type="email"],
#primary input[type="url"],
#primary input[type="number"],
#primary input[type="tel"],
#primary textarea {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #cfcfcf;
    color: #4f4f4f;
    margin-bottom: 10px;
}

#primary input[type="submit"] {
    display: block;
    margin-top: -18px;
}

#primary div.wpcf7 img.ajax-loader {
    margin-top: -22px;
    position: absolute;
    right: 7px;
}

#text-11 input[type="submit"] {
    margin-top: 0;
}


/**********************************TESTIMONIALS**********************************/

.entry-content .hms-testimonial-group {
    background: transparent url("./images/testimonials_bg.png") repeat-y scroll center top;
}

.hms-testimonial-group {
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    -moz-column-gap: 0;
}

.entry-content .hms-testimonial-container {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 10px 0 12px;
    padding: 25px 10px;
    width: 95%;
}

.entry-content .hms-testimonial-container .testimonial {
    font-size: 15px;
    font-style: italic;
}

.hms-testimonial-group div {
    height: auto !important;
}

.entry-content .hms-testimonial-container .author {
    color: #c09232;
    float: right;
    font-family: roboto slab;
    font-size: 15px;
    margin-top: 18px;
    text-align: right;
}

#fab .hms-testimonials-rotator {
    text-align: center;
    margin-bottom: 25px;
}

#fab .hms-testimonials-rotator .author {
    color: #c09231;
    font-size: 20px;
    margin-top: 20px;
}

#fab .hms-testimonials-rotator .author::before {
    content: "- ";
    color: #c09231;
    font-size: 20px;
}


/**********************************FAQS**********************************/

div.qa-faqs {
    background: transparent url("./images/testimonials_bg.png") repeat-y scroll center top;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    -moz-column-gap: 0;
}

div.qa-faq {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 10px 0 12px;
    padding: 25px 10px 5px;
    width: 95%;
}

.qa-faq-title {
    line-height: 22px !important;
}

.qa-faq-title a {
    color: #000;
    font-size: 18px;
}


/**********************************BLOG / SEARCH**********************************/

div.postscontainer {
    background: transparent url("./images/testimonials_bg.png") repeat-y scroll center top;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    -moz-column-gap: 0;
}

div.category-blog,
div.search-post {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 10px 0 12px;
    padding: 25px 10px 15px;
    width: 95%;
}

div.category-blog .entry-title a:link,
div.category-blog .entry-title a:visited,
div.search-post .entry-title a:link,
div.search-post .entry-title a:visited {
    color: #000;
    font-family: Roboto Slab;
    font-size: 18px;
    font-weight: 200;
}

div.category-blog .entry-title a:link:hover,
div.category-blog .entry-title a:visited:hover,
div.search-post .entry-title a:link:hover,
div.search-post .entry-title a:visited:hover {
    color: #c09232;
}

.single #content .entry-title {
    color: #000;
    font-family: roboto slab;
    font-size: 24px;
    font-weight: 200;
}

.safari .single #content .entry-title {
    font-weight: 300;
}

.single div.category-blog {
    border: medium none;
    display: block;
}


/**********************************LOGOS**********************************/

#logos-wrap {
    background: #fff;
}

#logos {
    color: #4f4f4f;
    padding: 40px 0;
}

#logos li {
    list-style: none;
    margin: 0;
}

.logo-table {
    width: 100%;
}

.logo-table.cell6 {
    padding-bottom: 40px;
}

.logo-row {
    display: table-row;
}

.logo-cell {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0 10px;
    box-sizing: border-box;
}

.logo-table.cell6 .logo-cell {
    width: 16%;
}

.logo-table.cell5 .logo-cell {
    width: 19.3%;
}


/**********************************FOOTER**********************************/

#footerwrap {
    background: #362c19;
}

#footer {
    font-size: 15px;
    line-height: 22px;
    padding: 35px 0 0px;
}

#footer .widget-title {
    color: #fff;
    font-family: Roboto Slab;
    font-size: 24px;
    font-weight: 200 !important;
    margin-bottom: 25px;
}

.safari #footer .widget-title {
    font-weight: 300 !important;
}

#footer li {
    display: inline-block;
    list-style: outside none none;
    vertical-align: top;
    width: 25%;
}

#footer li#nav_menu-2 {
    width: 24%;
    padding-right: 10px;
    box-sizing: border-box;
}

#footer li#nav_menu-3 {
    width: 21%;
    padding-right: 10px;
    box-sizing: border-box;
}

#footer li#miniloops-2 {
    box-sizing: border-box;
    font-size: 14px;
    padding-right: 45px;
    width: 30%;
}

#footer li#text-3 {}

#footer #text-4 {
    border-top: 1px solid #63502b;
    margin-top: 20px;
    padding-top: 15px;
    text-align: center;
    width: 100%;
}

#footer li ul {
    margin: 0;
}

#footer li ul li {
    display: block;
    line-height: normal;
    width: 100%;
}

#footer a {
    color: #c09231;
    font-size: 15px;
    line-height: 22px;
}

#footer a:hover {
    color: #63502b;
}

#footer em {
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

#footer .miniloops li:first-child {
    margin-bottom: 15px;
    padding-bottom: 15px;
}

#footer a#title {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

#bottombarwrap {
    box-shadow: none !important;
}

#bottombar {
    font-size: 13px;
    line-height: 38px;
}

#bottombar .left {
    float: left;
}

#bottombar .right {
    float: right;
}

#bottombar a {
    color: #fff;
}

#bottombar a:hover {
    color: #c09231;
}

#text-4 a {
    color: #fff;
}

.ftestimonial {
    border-bottom: 1px solid #63502b;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.ftestimonial .author {
    color: #c09231;
    margin-top: 10px;
}

.ftestimonial .author::before {
    content: "- ";
}


/**********************************MISC**********************************/

#responsive-menu-container {
    top: 38px !important;
}

.responsive-menu-inner {
    margin-top: 0 !important;
}

.arrowmove::after {
    content: "»";
    padding-left: 4px;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.arrowmove:hover::after {
    padding-left: 7px;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.btn {
    padding: 10px;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.btn.tan,
.btn.grey {
    color: #fff !important;
    font-family: Roboto Slab !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-right: 20px;
    padding: 10px 20px;
    text-transform: uppercase !important;
}

.chrome .btn.tan,
.chrome .btn.grey {
    font-weight: 300 !important;
}

.btn.tan {
    background: #c09231 !important;
}

.btn.grey {
    background: #8e8e8e !important;
}

.btn.grey::before {
    background: #717171 !important;
}

.hvr-shutter-out-vertical a,
#wrapper .hvr-shutter-out-vertical,
#splash .hvr-shutter-out-vertical {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    background: #e1e1e1 none repeat scroll 0 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    display: inline-block;
    position: relative;
    transform: translateZ(0px);
    -ms-transform: translateZ(0px);
    -webkit-transform: translateZ(0px);
    transition-duration: 0.3s;
    transition-property: color;
    vertical-align: middle;
}

.hvr-shutter-out-vertical a::before,
#wrapper .hvr-shutter-out-vertical::before,
#splash .hvr-shutter-out-vertical::before {
    background: #906712 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleY(0);
    -ms-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: 50% 50% 0;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    z-index: -1;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

#splash .hvr-shutter-out-vertical:hover,
#splash .hvr-shutter-out-vertical:focus,
#splash .hvr-shutter-out-vertical:active,
#wrapper .hvr-shutter-out-vertical:hover,
#wrapper .hvr-shutter-out-vertical:focus,
#wrapper .hvr-shutter-out-vertical:active,
.hvr-shutter-out-vertical a:hover,
.hvr-shutter-out-vertical a:focus,
.hvr-shutter-out-vertical a:active {
    color: white;
}

#splash .hvr-shutter-out-vertical:hover::before,
#splash .hvr-shutter-out-vertical:focus::before,
#splash .hvr-shutter-out-vertical:active::before,
#wrapper .hvr-shutter-out-vertical:hover::before,
#wrapper .hvr-shutter-out-vertical:focus::before,
#wrapper .hvr-shutter-out-vertical:active::before,
.hvr-shutter-out-vertical a:hover::before,
.hvr-shutter-out-vertical a:focus::before,
.hvr-shutter-out-vertical a:active::before {
    transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
}

#access .hvr-underline-reveal a {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    display: inline-block;
    overflow: hidden;
    position: relative;
    transform: translateZ(0px);
    vertical-align: middle;
}

#access .hvr-underline-reveal a::before {
    background: #c09231 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(5px);
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    z-index: -1;
}

#access .hvr-underline-reveal.current-menu-item a::before,
#access .hvr-underline-reveal.current-page-ancestor a::before,
#access .hvr-underline-reveal.current-menu-ancestor a::before,
#access .hvr-underline-reveal ul li a::before {
    height: 0px;
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    background: transparent;
}

#access .hvr-underline-reveal a:hover::before,
#access .hvr-underline-reveal a:focus::before,
#access .hvr-underline-reveal a:active::before {
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
}

iframe {
    max-width: 100% !important;
}

.pdf {
    background: url('./images/pdf.png') no-repeat 0 2px transparent;
    padding-left: 20px;
}




/**********************************RESPONSIVE STYLES**********************************/

#wrapper,
div.menu,
#branding,
#header,
#topbar,
#plax-slide #slide,
#footer,
#bottombar,
#splash,
#fab,
#fab-title p,
#logos,
#header,
#pagetitle {
    width: 1240px;
    margin: 0 auto;
}

#topbarwrap,
#bottombarwrap,
#splash-wrap,
#fab-wrap,
#logos-wrap,
#pagetitle-wrap {
    width: 100%;
    margin: 0 auto;
}

#topbar,
#bottombar,
#splash,
#fab,
#fab-title p,
#logos,
#pagetitle {
    margin: 0 auto;
}

.mobileshow {
    display: none !important;
}

@media screen and (max-width: 1280px) {
    #wrapper,
    #header,
    #topbar,
    #plax-slide #slide,
    #footer,
    #bottombar,
    #splash,
    #fab,
    #fab-title p,
    #logos,
    #header,
    #pagetitle {
        width: 94% !important;
    }
    div.menu,
    #branding,
    #main,
    #footer {
        margin: 0 auto;
        width: 100%;
    }
    #access a,
    #access ul ul {
        margin: 0 4px !important;
    }
    #splash .textwidget {
        padding: 0 0 60px 0;
    }
    #splash #glenn {
        right: 0px;
    }
}

@media screen and (max-width: 1180px) {
    #access a,
    #access ul ul {
        margin: 0px !important;
    }
    #access a {
        font-size: 12px;
    }
    #splash .textwidget {
        max-width: 590px;
        padding: 0 0 40px;
    }
}


@media screen and (max-width: 1024px) {
    #container {
        display: block;
        width: 100%;
    }
    #primary,
    #secondary {
        display: block;
        min-width: unset;
        width: 100%;
    }



    #main {
        padding: 40px 0 0;
    }
    #primary .widget-container {
        box-shadow: none;
    }
    #footer li#nav_menu-2 {
        width: 30%;
    }
    #footer li#nav_menu-3 {
        width: 26%;
    }
    #footer li#miniloops-2 {
        padding-right: 0;
        width: 44%;
    }
    #footer li#text-3 {
        margin-top: 15px;
        width: 100%;
    }
    #fab-title p {
        padding: 15px 50px 0;
    }
    #pagetitle-wrap #pagetitle #breadcrumbs {
        float: left;
        line-height: normal;
        margin-top: 24px;
        width: 100%;
    }
}


@media screen and (max-width: 980px) {
    #slide #text-slider {
        max-width: 360px;
    }
    #slide #text-slider .text-slideshow article {
        line-height: 36px !important;
    }
    #splash #glenn {
        opacity: 0.25;
    }
    #splash .textwidget {
        max-width: unset;
    }
}


@media screen and (max-width: 940px) {
    /*#container {
 margin: 0 -32.5319149% 0 0;
 }
 #content {
 width: 65.5957447%;
 }*/
    #footer li#nav_menu-2,
    #footer li#nav_menu-3,
    #footer li#miniloops-2 {
        width: 33%;
    }
    #bottombarwrap {
        height: auto;
    }
    #bottombar {
        padding: 10px 0px;
    }
    #bottombarwrap .left,
    #bottombarwrap .right {
        float: none;
        line-height: 16px;
        text-align: center;
    }
    #bottombarwrap .right {
        margin-top: 10px;
    }
    #fab .fab {
        width: 49%;
    }
}


@media screen and (max-width: 768px) {


    #eBook .left,
    #eBook .right {
        display: block;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
    }

    #eBook .left img {
        display: block;
        width: 50%;
        margin: 0 auto 30px auto;
    }

    .btn.gold {
        margin: 20px auto;
    }

    #wrapper {
        width: 100% !important;
    }
    #wrapper {
        padding: 0 20px;
    }
    #content,
    #primary,
    #secondary {
        margin: 0;
        width: 100%;
    }
    #text-slider {
        max-width: none !important;
        width: 100% !important;
        display: none;
    }
    #estimate {
        max-width: none !important;
    }
    #estimate .left {
        float: left;
        text-align: left;
        width: 50%;
    }
    #estimate .right {
        float: right;
        padding-top: 12px;
        width: 46%;
    }
    #footer li#nav_menu-2,
    #footer li#nav_menu-3,
    #footer li#miniloops-2,
    #footer li#text-3 {
        width: 50%;
    }
    #footer li#miniloops-2 {
        margin-top: 15px;
        padding-right: 40px;
    }
    #splash .widget-title {
        padding: 35px 0 25px;
    }
    #fab-title p {
        padding: 15px 0px 0;
    }
    #pagetitle-wrap #pagetitle {
        padding: 0px;
    }
    #container {
        padding: 0px;
    }
    #pagetitle-wrap {
        height: auto;
    }
    #pagetitle-wrap #pagetitle h1 {
        float: none;
        line-height: normal;
        padding: 12px 0;
        text-align: center;
    }
}


@media screen and (max-width: 603px) {
    .one_half {
        padding: 0 !important;
        width: 100% !important;
    }
    .home .entry-content h2 {
        font-size: 30px !important;
        margin-bottom: 40px !important;
    }
    .logo-table.cell6 {
        padding-bottom: 0px;
    }
    .logo-cell {
        padding-bottom: 20px;
        width: 48% !important;
    }
}


@media screen and (max-width: 560px) {
    .mobileshow {
        display: block;
    }
    #estimate {
        background: none;
        width: 100%;
    }
    #slide .btn {
        box-shadow: 0 1px 10px #000 !important;
        font-weight: normal !important;
        line-height: 38px !important;
    }
    #estimate .left,
    #estimate .right {
        display: none;
    }
    #fab .fab {
        width: 100%;
    }
    .entry-content .hms-testimonial-container,
    div.qa-faq,
    div.category-blog,
    div.search-post {
        margin: 0 !important;
    }
    .hms-testimonial-group,
    div.qa-faqs,
    div.postscontainer {
        background: none !important;
        -moz-column-count: 1;
    }
}


@media screen and (max-width: 480px) {
    #topbar .search {
        display: none;
    }
    .social {
        float: none;
        clear: both;
        padding-bottom: 5px;
        text-align: center;
    }

    #topbar .booksignup {
        float: none;
        max-height: 38px;
        padding: 5px 0;
        text-align: center;
    }
    #footer li#nav_menu-2,
    #footer li#nav_menu-3,
    #footer li#miniloops-2,
    #footer li#text-3 {
        width: 100%;
        padding: 0px;
    }
    #footer li#nav_menu-3,
    #footer li#miniloops-2,
    #footer li#text-3 {
        margin-top: 15px;
    }
    #slide .btn {
        margin: 0 !important;
    }
    .btn {
        box-sizing: border-box;
        margin: 0 0 10px !important;
        text-align: center;
        width: 100%;
    }
    #splash .textwidget {
        padding: 0 0 5px;
    }
    #splash #glenn {
        display: none;
    }
    #pagetitle-wrap #pagetitle h1 {
        font-size: 24px;
    }
}


@media screen and (max-width: 420px) {
    #logo {
        margin-top: 35px;
    }
    #logo img {
        max-width: 72%;
        height: auto;
    }
    #splash .widget-title {
        font-size: 32px;
    }
    #fab-title h3 {
        font-size: 28px;
    }
    .logo-cell {
        width: 100% !important;
    }
    #primary .widget-container {
        padding: 30px 10px;
    }
    #content img {
        height: auto !important;
        max-width: 100% !important;
        width: 100% !important;
    }
    .gallery .gallery-item {
        width: 100%;
    }
    #content img.secure-site,
    #content img.wpcf7-form-control {
        width: inherit !important;
    }
    #content #author-avatar {
        display: none;
    }
    #content #author-description {
        margin: 0;
    }
    #headwrap {
        height: 80px;
    }
    #logo {
        margin-top: 16px;
    }
    #click-menu {
        top: 54px !important;
    }
}


@media screen and (max-width: 320px) {}


@media only screen and (min-width:1280px) {
    .scroll-back-to-top-wrapper {
        display: none;
    }
}


@import 'modules/nav-glenn';

.ebook {
    display: none;
}