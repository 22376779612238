   .nav-cont{
	   	// width: 900px;
	   	display: inline-block;
	   	margin-top: 36px;
	   	float: right;

	   	@media screen and (max-width: 920px) {
		   	margin-top: 0;
		   	background-color: rgba(0, 0, 0, 0.75);

		   	.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
		   		color: #fff; 
		   	} 
	   	}
   }


    .ubermenu-content-block{
    		padding: 12px 12px 12px 0;
    		margin: 0;

    	.widget{ 

    	}

    	.widget_text{

			h3.ubermenu-widgettitle{
				margin: 0 0 8px 0;
				padding: 0;
			}

			a:last-child{
				@extend .hvr-shutter-out-vertical, .btn, .tan;
				float: right;
				margin-top: 12px;
			}

    	} 

		.widget_media_image{

			img{
				display: block;
				max-width: 100%;
				height: auto;
				padding: 4px;
				line-height: 1.42857;
				background-color: #fff;
				border: 1px solid #ddd;
				border-radius: 4px;
				transition: all 0.2s ease-in-out;
			}
		}

    }